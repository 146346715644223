import React, { useEffect, useState } from "react";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import ImageUploader from "../components/ImgUpload";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const Users = ({ authToken }) => {
  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const ToggleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const Users = async () => {
      try {
        const response = await api.get("users/all", {
          headers: {
            token: authToken,
          },
        });

        setUsersData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    };
    Users();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => async () => {
    try {
      const response = await api.delete(`users/${id}`, {
        headers: {
          token: authToken,
        },
      });
      setUsersData(usersData.filter((row) => row.user_id !== id));
      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `users/${newRow.user_id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );
      console.log(response.data);
      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleUploadClick = (id) => async () => {
    ToggleOpen();
    setUserId(id);
  };
  const columns = [
    {
      field: "profile_img", // You can use a field that represents the image URL
      headerName: "ID",
      width: 70,
      headerName: "Image",

      renderCell: (params) => (
        <img
          src={`http://localhost:4400/${params.value}`}
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      field: "user_id", // You can use a field that represents the image URL
      headerName: "ID",
      width: 70,
    },
    {
      field: "client_id", // You can use a field that represents the image URL
      headerName: "C.ID",
      width: 70,
    },
    { field: "full_name", headerName: "Name", width: 200, editable: true },
    { field: "user_mobile", headerName: "Mobile", width: 150, editable: true },
    { field: "user_name", headerName: "Username", width: 100, editable: true },
    {
      field: "isApproved",
      headerName: "Approved",

      type: "boolean",
      width: 140,
      editable: true,
    },
    {
      field: "isAdmin",
      headerName: "Admin",

      type: "boolean",
      width: 140,
      editable: true,
    },

    { field: "createdAt", headerName: "Date", width: 250 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FileUploadIcon />}
            label="Upload"
            onClick={handleUploadClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mt-2">
      <Dialog
        open={open}
        keepMounted
        onClose={ToggleOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Upload Profile img"}</DialogTitle>
        <DialogContent>
          <ImageUploader
            setOpen={ToggleOpen}
            userId={userId}
            authToken={authToken}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={ToggleOpen}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rows={usersData}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.user_id}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing row update:", error);
            // Optionally handle the error (e.g., show an error message)
            setSnackbar({ children: error.message, severity: "error" });
          }}
        />
      </div>
    </div>
  );
};

export default Users;
