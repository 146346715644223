import { useContext, useState, useEffect } from "react";

import { login } from "../authContext/apiCalls";
import { AuthContext } from "../authContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginPage = ({ clientId }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [success, setSuccess] = useState(false);
  const { dispatch, error } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        navigate("/");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [success, navigate]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const msg = await login({ username, password, clientId }, dispatch);
      setSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      className=""
      style={{
        backgroundColor: "rgba(236, 226, 230, 0.8)", // Semi-transparent red
      }}
    >
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="col-sm-10 col-lg-4 shadow p-3 mb-5 bg-body rounded">
          <p className="fw-semibold">Login Page</p>

          <div className="d-flex flex-column">
            <TextField
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              variant="outlined"
              margin="normal"
              size="small"
            />

            <TextField
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              variant="outlined"
              size="small"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleToggle}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </div>
          <br />
          {error && <p className="alert alert-danger">{error}</p>}
          {!error &&
            success && ( // Only show success message if there's no error
              <p className="alert alert-success">
                Login successful! Redirecting...
              </p>
            )}
          <button
            type="button"
            className="btn btn-success"
            onClick={handleLogin}
          >
            Login
          </button>
          <Link
            to="/forgot-password"
            className="text-decoration-none float-end"
          >
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
