import { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./authContext/AuthContext";
import { useClientId } from "./components/ClientIdContext";
import OrdersPay from "./pages/OrdersPay";
import Orders from "./pages/Orders";
import AppBarComponent from "./components/AppBarComponent";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import RegPage from "./pages/RegPage";
import Users from "./pages/Users";
function App() {
  const { user } = useContext(AuthContext);
  const userToken = user?.accessToken;
  const { clientId } = useClientId();
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppBarComponent authToken={userToken} />}>
            <Route
              path="/orders"
              element={<Orders authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route
              path="/orders_pay"
              element={<OrdersPay authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route
              path="/register user"
              element={<RegPage authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route
              path="/users"
              element={<Users authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/login" element={<LoginPage clientId={clientId} />} />
          <Route
            path="/forgot-password"
            element={<ForgotPassword clientId={clientId} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
