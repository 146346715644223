import React, { useEffect, useState } from "react";
// import socketIOClient from "socket.io-client";
import axios from "axios";
import Select from "../components/Select";
import SelectCourier from "../components/SelectCourier";
import { LinearProgress } from "@mui/material";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const OrdersPay = ({ authToken, clientId }) => {
  const [response, setResponse] = useState("");
  const [orderP, setOrderP] = useState("");
  const [progress, setProgress] = useState();
  const [stores, setStores] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [realTimeData, setRealTimeData] = useState([]);
  const [fileData, setFileData] = useState(null);

  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedCourierId, setselectedCourierId] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const handleSelectChange = (value) => {
    setSelectedStoreId(value);
  };
  const handleSelectChangeC = (value) => {
    setselectedCourierId(value);
  };
  useEffect(() => {
    const processingJobId = localStorage.getItem("processingJobId");
    if (processingJobId) {
      setIsProcessing(true);
      pollJobStatus(processingJobId);
    }
    api
      .get("stores/all", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    api
      .get("couriers/all", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setCouriers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // const eventName = `response_${clientId}`;
    // const socket = socketIOClient.connect("https://vapi.vnoirs.com/");

    // socket.on(eventName, (data) => {
    //   setRealTimeData((prevData) => [...prevData, data]);
    // });
    // return () => socket.disconnect();
  }, []);

  const handleDivClick = (dataArray, arrayName) => {
    const popupWindow = window.open("", "_blank", "width=600,height=400");

    if (popupWindow) {
      const htmlContent = `
        <div style="font-family: Arial, sans-serif;">
          <h2>${arrayName}</h2>
          <div style="display: flex; flex-wrap: wrap;">
            ${Object.entries(dataArray)
              .map(
                ([key, value]) => `
                <div style="border: 1px solid #ccc; border-radius: 8px; padding: 10px; margin: 10px; width: 230px;  text-align: center;">
                  <h3 style="textalign:center">${key}</h3>
                  ${Object.entries(value)
                    .map(
                      ([subKey, subValue]) =>
                        `<p style="border: 1px solid #ccc; border-radius: 8px; padding: 10px; margin: 10px; width: 200px;"><strong>${subKey}:</strong> ${subValue}</p>`
                    )
                    .join("")}
                </div>
              `
              )
              .join("")}
          </div>
        </div>
      `;
      popupWindow.document.write(htmlContent);
    } else {
      alert(
        "Popup blocked! Please allow popups for this site to see the data."
      );
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  // const handleFileUpload = () => {
  //   if (!fileData && realTimeData.length === 0) {
  //     if (selectedFile && selectedStoreId && selectedCourierId) {
  //       setLoading(true);
  //       const formData = new FormData();
  //       formData.append("excelFile", selectedFile);
  //       formData.append("storeId", selectedStoreId); // Assuming storeId is a variable containing the store ID
  //       formData.append("courierId", selectedCourierId);
  //       formData.append("clientId", clientId);
  //       api
  //         .post("upload/mp", formData, {
  //           headers: {
  //             token: authToken,
  //           },
  //         })
  //         .then((response) => {
  //           setFileData(response.data);
  //           setLoading(false);
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //           setLoading(false);
  //         });
  //     } else {
  //       alert("please Select All Fields");
  //     }
  //   } else {
  //     alert("please refresh the page a file is already in progress");
  //   }
  // };
  const handleFileUpload = async () => {
    if (!isProcessing) {
      if (selectedFile && selectedStoreId && selectedCourierId) {
        const formData = new FormData();
        formData.append("excelFile", selectedFile);
        formData.append("storeId", selectedStoreId); // Assuming storeId is a variable containing the store ID
        formData.append("courierId", selectedCourierId);
        formData.append("clientId", clientId);

        try {
          setIsProcessing(true);
          const res = await api.post("upload/file", formData, {
            headers: {
              token: authToken,
            },
          });
          setOrderP(res.data.result.Orders.length);

          setFileData(res.data.result);
          setResponse("Orders processing initiated. Please wait...");
          pollJobStatus(res.data.jobId); // Start polling for job status
          localStorage.setItem("processingJobId", res.data.jobId);
        } catch (error) {
          console.error("Error processing orders:", error.message);
          setResponse("Error: " + error.message);
          setIsProcessing(false);
        }
      } else {
        alert("please Select All Fields");
      }
    } else {
      alert("A file upload is already in progress.");
    }
  };
  const pollJobStatus = async (jobId) => {
    try {
      const res = await api.get(`upload/file/${jobId}`, {
        headers: {
          token: authToken,
        },
      });

      if (res.data.status === "completed") {
        setResponse("Orders processing completed.");
        setOrderP(res.data.orderPro);
        setProgress(100);
        setIsProcessing(false);
        localStorage.removeItem("processingJobId");
      } else if (res.data.status === "processing") {
        setIsProcessing(true);
        setOrderP(res.data.orderPro);
        setProgress(res.data.progress);
        setTimeout(() => {
          pollJobStatus(jobId);
        }, 5000);
      } else {
        setResponse("Orders processing ." + res.data.status);
        setIsProcessing(false);
        localStorage.removeItem("processingJobId");
      }
    } catch (error) {
      console.error("Error polling job status:", error.message);
      setResponse("Error: " + error.message);
      setIsProcessing(false); // Reset processing flag in case of error
      localStorage.removeItem("processingJobId");
    }
  };

  async function stopUpload() {
    api
      .post(
        "upload/stop-loop",
        {},
        {
          headers: {
            token: authToken,
          },
        }
      )
      .then((response) => {
        setResponse("");
        setOrderP("");
        setProgress();
        // setSelectedFile(null);
        // setRealTimeData([]);
        setFileData(null);
        setIsProcessing(false);
        setSelectedStoreId("");
        setselectedCourierId("");
        localStorage.removeItem("processingJobId");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="container-fluid mt-5">
      {(response || progress) && (
        <div className="shadow p-3 mb-2 bg-body rounded">
          {response && <div>{response}</div>}
          {progress && (
            <div>
              {" "}
              <p>orders: {orderP}</p>
              <LinearProgress
                value={progress}
                valueBuffer={progress}
                variant="buffer"
              />
            </div>
          )}
        </div>
      )}
      <div className="shadow p-3 mb-2 bg-body rounded d-flex justify-content-around">
        <div className="">
          <Select
            stores={stores}
            value={selectedStoreId}
            onChange={handleSelectChange}
          />
        </div>
        <div className="">
          <SelectCourier
            couriers={couriers}
            value={selectedCourierId}
            onChange={handleSelectChangeC}
          />
        </div>
        <div className="">
          <input
            className="form-control"
            type="file"
            onChange={handleFileSelect}
          />
        </div>

        {!fileData && !isProcessing > 0 && (
          <div className="">
            <div className="">
              <button className="btn btn-info" onClick={handleFileUpload}>
                Upload File
              </button>
            </div>
          </div>
        )}
        {(fileData || isProcessing > 0) && (
          <div className="">
            <button className="btn btn-danger" onClick={stopUpload}>
              Stop Upload
            </button>
          </div>
        )}
      </div>

      {fileData && (
        <div>
          <div className="d-flex flex-wrap justify-content-around">
            {fileData &&
              Object.keys(fileData).map((arrayName, index) => (
                <div
                  className="shadow p-3 mb-2 bg-body rounded"
                  key={index}
                  onClick={() => handleDivClick(fileData[arrayName], arrayName)}
                >
                  <p>{arrayName}</p>
                  <p> {fileData[arrayName].length}</p>
                </div>
              ))}
          </div>
          {/* <h4>Real-Time Response</h4> */}
        </div>
      )}

      {/* <div style={{ height: "500px", overflowY: "auto" }}>
        <p> {realTimeData.length}</p>

        <ul>
          {realTimeData
            .slice(0)
            .reverse()
            .map((data, index) => (
              <li key={index}>{data.message}</li>
            ))}
        </ul>
      </div> */}
    </div>
  );
};

export default OrdersPay;
