// DataTable.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useRedirectIfUnauthorized } from "../hooks/ClientIdHook";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Orders = ({ authToken }) => {
  useRedirectIfUnauthorized();
  const [data, setData] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);
  const [btnID, setBtnID] = useState(null);

  useEffect(() => {
    api
      .get("stores/all", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setStoresData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  async function getOrdersOfStore(store) {
    setBtnID(store);
    setLoading(true);
    api
      .get(`orders/ofstore/${store}`, {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false); // Data has been fetched, set loading to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Error occurred, set loading to false
      });
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "store_id", headerName: "SID", width: 100 },
    { field: "order_id", headerName: "Order", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },

    {
      field: "isPaid",
      headerName: "Is paid?",
      type: "boolean",
      width: 140,
      editable: true,
    },
    { field: "createdAt", headerName: "C Date", width: 250 },
    { field: "updatedAt", headerName: "UpDate", width: 250 },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 100,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteOrder(params.row.id)}
          />
        );
        return actions;
      },
    },
  ];
  const deleteOrder = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Product?"
    );
    try {
      if (confirmed) {
        const response = await api.delete(`orders/${id}`, {
          headers: { token: authToken },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "You Did not confirm to delet Not Deleted ",
          severity: "warning",
        });
      }
    } catch (error) {
      console.error("Error delet order data:", error);

      setSnackbar({
        children: error.message,
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        height={200}
        width={200}
        my={4}
        display="flex"
        alignItems="center"
        gap={4}
        p={2}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Snackbar
        open={snackbar ? true : false}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
      <div className="container mt-5">
        <div className="">
          {storesData.map((store) => (
            <button
              key={store.id}
              className={`btn btn-outline-info m-1 ${
                btnID === store.id ? "active" : ""
              }`}
              onClick={() => getOrdersOfStore(store.id)}
            >
              {store.public_name}
            </button>
          ))}
        </div>
      </div>
      {data.length > 0 ? (
        <div className="container mt-2">
          <h1>Orders</h1>
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowHeight={25}
              rows={data}
              columns={columns}
              pageSize={5}
              getRowId={(row) => row.id}
            />
          </div>
        </div>
      ) : (
        <p>Please select a store</p>
      )}
    </div>
  );
};

export default Orders;
